import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Text, Icon } from "@website-builder/ui/shared/elements";
import {
	MainWrapper,
	Caret,
	SelectWrapper,
	SelectTagWrapper,
	ErrorSection,
	SelectedItemWrapper,
	OptionsList,
	OptionLabel,
	IconWrapper,
	LabelWithHelpText,
} from "./styles";
import { getUniqueUserId } from "@website-builder/utilities/utils/uniqueId.js";
const isB2u = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";
const tooltipId = getUniqueUserId();
const Select = (props) => {
	const {
		label,
		required,
		error = [{}, () => {}],
		children,
		onChange = () => {},
		name,
		helpText,
		value = null,
		showErrors,
		placeholder,
		className,
		validateInput,
		isHubspotForm = false,
	} = props;
	let activeItemRef = useRef(null);
	const [errors, setErrors] = error;
	const [expanded, setExpanded] = useState(false);
	const [selectedItem, setSelectedItem] = useState(value);
	const [selectedItemText, setSelectedItemText] = useState(
		value !== null
			? children.find((item) => item.props?.value === value)?.props?.children
			: placeholder || "Please select...",
	);
	const [focused, setFocused] = useState(false);
	let options = [];
	if (placeholder) {
		options.push({
			type: "placeholder",
			text: placeholder,
			value: "",
		});
	} else if (value === null) {
		options.push({
			type: "placeholder",
			text: "Please select...",
			value: "",
		});
	}

	children.forEach((item) => {
		if (item?.type === "option")
			options?.push({
				type: "option",
				text: item.props?.children,
				value: item.props?.value,
			});
		else if (item?.props?.variant) {
			options.push({ type: "text", value: item });
		} else options.push({ type: "separator", value: item });
	});
	useEffect(() => {
		if (!isB2u && !isHubspotForm) {
			if (value !== null) {
				const selectValue =
					typeof value === "object" ? JSON.stringify(value) : value;
				const valueText = children.find(
					(item) =>
						item?.type === "option" && item?.props?.value === selectValue,
				)?.props?.children;
				setSelectedItem(value);
				setSelectedItemText(valueText);
			} else {
				setSelectedItem("");
				setSelectedItemText(placeholder || "Please select...");
			}
		}
	}, [value, children]);

	const validate = (value) => {
		if (required) {
			if (!value) {
				setErrors({
					...errors,
					[name]: "Choose an option",
				});
				return;
			} else {
				setErrors({
					...errors,
					[name]: "",
				});
				return;
			}
		} else {
			setErrors({
				...errors,
				[name]: "",
			});
			return;
		}
	};
	useEffect(() => {
		if (validateInput > 0) {
			validate(selectedItem);
		}
	}, [validateInput]);
	const switchOption = (e) => {
		e.preventDefault();
		const value = e.target?.getAttribute("data-value");
		validate(value);
		onChange({ target: { name: name, value: value } }, e);
	};

	useEffect(() => {
		window.addEventListener("click", (e) => {
			if (e.target !== activeItemRef?.current) {
				setExpanded(false);
			}
		});
	}, [children, activeItemRef]);

	const handleOnBlur = (event) => {
		validate(event?.target?.value);
		setFocused(false);
	};
	const handleSelectChange = (event) => {
		setSelectedItem(event?.target?.value);
		onChange?.(event);
	};

	const handleCaretClick = (e) => {
		e.preventDefault();
		e.stopPropagation(); // Ensure the click does not propagate to the parent button
		setExpanded(!expanded);
	};

	return (
		<MainWrapper className={className}>
			<LabelWithHelpText>
				{label ? (
					<Text
						variant="label_M"
						className={`${required ? "required" : ""} label-text`}
					>
						{label}
					</Text>
				) : null}
				{helpText ? (
					<IconWrapper>
						<Icon
							iconName="info"
							className="info-icon"
							aria-label="info-icon"
							tabIndex="0"
							aria-describedby={tooltipId}
						/>
						<div class="info-help-text" id={tooltipId}>
							<Text variant="paragraph_S" className={`help-text`}>
								{helpText.replaceAll("<br>", "\n")}
							</Text>
						</div>
					</IconWrapper>
				) : null}
			</LabelWithHelpText>
			{isB2u || isHubspotForm ? (
				<SelectTagWrapper
					type="select"
					value={selectedItem}
					onChange={handleSelectChange}
					onFocus={() => setFocused(true)}
					onBlur={handleOnBlur}
					name={name}
					className={selectedItem ? "" : "placeholder-text"}
				>
					{placeholder ? <option value="">{placeholder}</option> : null}
					{children}
				</SelectTagWrapper>
			) : (
				<SelectWrapper expanded={expanded}>
					<SelectedItemWrapper error={errors[name] && showErrors}>
						<button
							className={`btn ${
								selectedItem ? "style-selected-item" : "placeholder"
							} `}
							onClick={(e) => {
								e.preventDefault();
								setExpanded(true);
							}}
							ref={activeItemRef}
						>
							{selectedItemText}
							<Caret expanded={expanded} onClick={handleCaretClick} />
						</button>
					</SelectedItemWrapper>
					<OptionsList expanded={expanded}>
						{options.map((item, index) => (
							<React.Fragment key={index}>
								{item.type === "placeholder" ? (
									<button
										className="btn gray-text"
										onClick={switchOption}
										data-value={item.value}
									>
										{item.text}
									</button>
								) : item.type === "option" ? (
									<button
										className={`btn dark-on-hover ${
											item.value && item.value == selectedItem
												? "selected"
												: null
										}`}
										onClick={switchOption}
										data-value={item.value}
									>
										{item.text}
									</button>
								) : item.type === "text" ? (
									<OptionLabel>{item.value}</OptionLabel>
								) : item.type === "separator" ? (
									<React.Fragment>{item.value}</React.Fragment>
								) : null}
								<Caret expanded={expanded} />
							</React.Fragment>
						))}
					</OptionsList>
				</SelectWrapper>
			)}
			{errors[name] && showErrors
				? !focused && (
						<ErrorSection className="error-section">
							<Icon iconName="warning" />
							<Text variant="paragraph_S" className="errorMessage">
								{errors[name]}
							</Text>
						</ErrorSection>
					)
				: null}
		</MainWrapper>
	);
};

Select.propTypes = {
	label: PropTypes.string,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	name: PropTypes.string,
	showErrors: PropTypes.bool,
};

Select.defaultProps = {
	label: "",
	required: false,
	showErrors: false,
};

export default Select;
